import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { Accordion, Card } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAnglesUp,
  faArrowDown,
  faArrowUp,
  faAsterisk,
  faClock,
  faDollarSign,
  faHorseHead
} from '@fortawesome/free-solid-svg-icons';
import { Seo } from '../../modules/common/components/seo';
import { SectionHeader } from '../../modules/common/components/section-header';

import lodash from 'lodash';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './characters.scss';
import './tier-list.scss';
import { NikkeCharacter } from '../../modules/nikke/common/components/nikke-character';
import { NIKKECharacterRatings } from '../../modules/nikke/data/character-ratings';

const NikkeTierListPage: React.FC = () => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    NIKKECharacterRatings
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: 'R', value: 'R' },
          { label: 'SR', value: 'SR' },
          { label: 'SSR', value: 'SSR' }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Electric',
            tooltip: 'Electric',
            image: (
              <StaticImage
                src="../../images/nikke/icons/element_electric.png"
                width={20}
                alt="Electric"
              />
            )
          },
          {
            value: 'Fire',
            tooltip: 'Fire',
            image: (
              <StaticImage
                src="../../images/nikke/icons/element_fire.png"
                width={20}
                alt="Fire"
              />
            )
          },
          {
            value: 'Iron',
            tooltip: 'Iron',
            image: (
              <StaticImage
                src="../../images/nikke/icons/element_iron.png"
                width={20}
                alt="Iron"
              />
            )
          },
          {
            value: 'Water',
            tooltip: 'Water',
            image: (
              <StaticImage
                src="../../images/nikke/icons/element_water.png"
                width={20}
                alt="Water"
              />
            )
          },
          {
            value: 'Wind',
            tooltip: 'Wind',
            image: (
              <StaticImage
                src="../../images/nikke/icons/element_wind.png"
                width={20}
                alt="Wind"
              />
            )
          }
        ]
      },
      {
        key: 'weapon',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'AR',
            tooltip: 'Assault Rifle',
            image: (
              <StaticImage
                src="../../images/nikke/icons/weapon_ar.png"
                width={40}
                alt="Assault"
              />
            )
          },
          {
            value: 'MG',
            tooltip: 'Minigun',
            image: (
              <StaticImage
                src="../../images/nikke/icons/weapon_minigun.png"
                width={40}
                alt="Minigun"
              />
            )
          },
          {
            value: 'RL',
            tooltip: 'Rocket Launcher',
            image: (
              <StaticImage
                src="../../images/nikke/icons/weapon_rocket.png"
                width={40}
                alt="Rocket Launcher"
              />
            )
          },
          {
            value: 'SG',
            tooltip: 'Shotgun',
            image: (
              <StaticImage
                src="../../images/nikke/icons/weapon_shotgun.png"
                width={40}
                alt="Shotgun"
              />
            )
          },
          {
            value: 'SMG',
            tooltip: 'SMG',
            image: (
              <StaticImage
                src="../../images/nikke/icons/weapon_smg.png"
                width={40}
                alt="SMG"
              />
            )
          },
          {
            value: 'SR',
            tooltip: 'Sniper Rifle',
            image: (
              <StaticImage
                src="../../images/nikke/icons/weapon_sniper.png"
                width={40}
                alt="Sniper Rifle"
              />
            )
          }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Attacker',
            tooltip: 'Attacker',
            image: (
              <StaticImage
                src="../../images/nikke/icons/class_attacker.png"
                width={20}
                alt="Attacker"
              />
            )
          },
          {
            value: 'Defender',
            tooltip: 'Defender',
            image: (
              <StaticImage
                src="../../images/nikke/icons/class_defender.png"
                width={20}
                alt="Defender"
              />
            )
          },
          {
            value: 'Supporter',
            tooltip: 'Supporter',
            image: (
              <StaticImage
                src="../../images/nikke/icons/class_support.png"
                width={20}
                alt="Supporter"
              />
            )
          }
        ]
      },
      {
        key: 'burst',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'I',
            tooltip: 'Burst I',
            image: (
              <StaticImage
                src="../../images/nikke/icons/type_1.png"
                width={20}
                alt="Type I"
              />
            )
          },
          {
            value: 'II',
            tooltip: 'Burst II',
            image: (
              <StaticImage
                src="../../images/nikke/icons/type_2.png"
                width={20}
                alt="Type II"
              />
            )
          },
          {
            value: 'III',
            tooltip: 'Burst III',
            image: (
              <StaticImage
                src="../../images/nikke/icons/type_3.png"
                width={20}
                alt="Type III"
              />
            )
          }
        ]
      },
      {
        key: 'manufacturer',
        type: 'dropdown',
        values: [
          { label: 'Any Manufacturer', value: 'all' },
          {
            label: 'Abnormal',
            value: 'abnormal'
          },
          {
            label: 'Elysion',
            value: 'elysion'
          },
          {
            label: 'Missilis',
            value: 'missilis'
          },
          {
            label: 'Pilgrim',
            value: 'pilgrim'
          },
          {
            label: 'Tetra',
            value: 'tetra'
          }
        ]
      },
      // { key: 'speciality', type: 'dropdown', values: NikkeSpecialitiesArray },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = NIKKECharacterRatings;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.Name.toLowerCase().indexOf(
              activeFilters.searchText.toLowerCase()
            ) > -1
        );
        filtered = lodash.uniqBy(filtered, 'ID');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.Rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.Element.indexOf(activeFilters.element) > -1
        );
      }
      if (activeFilters.weapon && activeFilters.weapon !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.Weapon.toLowerCase() === activeFilters.weapon.toLowerCase()
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.Class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.manufacturer && activeFilters.manufacturer !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.Manufacturer.toLowerCase() ===
            activeFilters.manufacturer.toLowerCase()
        );
      }
      if (activeFilters.burst && activeFilters.burst !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.Burst_Type.toLowerCase() === activeFilters.burst.toLowerCase()
        );
      }
      // if (activeFilters.speciality && activeFilters.speciality !== 'all') {
      //   filtered = filtered.filter(
      //     (emp) =>
      //       emp.specialities &&
      //       emp.specialities.length > 0 &&
      //       emp.specialities.indexOf(activeFilters.speciality) > -1
      //   );
      // }
    }

    if (sortOption) {
      filtered = filtered.sort((a, b) =>
        a[sortOption] < b[sortOption] ? 1 : -1
      );
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, visibleCharacters, sortOption]);

  const sortingOptions = [
    { value: 'Story_LD', label: 'Story (early)' },
    { value: 'Story_HD', label: 'Story (late)' },
    { value: 'Boss_Solo', label: 'Bossing' },
    { value: 'PVP', label: 'PVP' }
  ];

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    // eslint-disable-next-line prefer-destructuring
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout
      className={'generic-page characters-page-nikke nikke-tier'}
      game="nikke"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier list</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/nikke/categories/category_tierlist.png"
            alt="Tier list"
          />
        </div>
        <div className="page-details ">
          <h1>NIKKE Tier list</h1>
          <h2>
            Tier list for Goddess of the Victory: NIKKE that rates all
            characters.
          </h2>
          <p>
            Last updated: <strong>13/10/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      <div className="tier-list-page nikke">
        <SectionHeader title="Tier List" />
        <Accordion className="tier-list-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>About the tier list</Accordion.Header>
            <Accordion.Body>
              <p>
                Previously, we tried to emphasize the main strengths of each
                Nikke and assess them accordingly. This allows certain Nikkes to
                rank very high but only for a specific purpose, causing a lot of
                misunderstanding. Therefore, moving forward update, we have
                decided to also consider the impact that each Nikke gives and
                analyze their overall usage in various game modes, including the
                recurrent Union Raid and Solo Raid.
              </p>
              <p>
                Remember that, with each new unit released, the tier list might
                shift to adapt to the new meta. In the tier lists below, each
                unit is evaluated independently based on their Burst sequence.
                For instance, avoid comparing Burst II units and Burst III units
                of the same rank. Instead, view each Burst list separately from
                the rest.
              </p>
              <p>
                <strong>Early vs High Deficit story</strong>
              </p>
              <p>
                <strong>Story (Low-deficit)</strong> is aimed toward beginners
                who are just starting out the game, thus not owning most of the
                meta characters yet and are forced to rely on alternatives. It
                is also less harsh when it comes to stat penalty from CP
                deficit, and the tier list should still be applicable for
                late-game clearing without high CP deficits. Note that this tier
                list assumes that OL is unavailable or has inefficient rolls,
                but expects proper teambuilding in cases like A2, where a healer
                is needed alongside her. This tier list is also applicable for
                Simulation Room: OverClock mode, since it has no Stat penalty at
                all.
              </p>
              <p>
                <strong>Story (High Deficit)</strong> refers to any late-game
                story-clearing content where people attempt to clear stages,
                whether in Campaign or Tower, with over &gt;25% CP deficit.
                Several features of this tier list include:
              </p>
              <ul>
                <li>
                  Some stages in later chapters have special mechanics, such as
                  hitcount, which can be handled easily by MG but not other
                  weapon types.
                </li>
                <li>
                  All units are assumed to have been adequately provided OL gear
                  and well-invested skill wise.
                </li>
                <li>
                  Extremely high CP deficit leads to a massive damage reduction,
                  which makes ATK buff more valuable.
                </li>
                <li>
                  This tier list assumes that people have access to a more
                  complete roster, thus team building becomes the primary
                  concern instead of individual performance.
                </li>
                <li>
                  This tier list appears to have more emphasis on faster
                  rotations and higher burst gen, as they allow you to kill
                  enemies before they can even kill you.
                </li>
                <li>
                  This tier list is a matter of who kills whom first. It's not
                  damage. As long as the enemy dies before they shoot, you gain
                  the upper hand. In fact, high CP deficits make enemy hit
                  harder, so tanking isn't really the ideal way to survive.
                  Offense is a good defense.
                </li>
              </ul>
              <p>
                <strong>Special markings:</strong>
              </p>
              <div className="special-markings">
                <ul>
                  <li>
                    <FontAwesomeIcon
                      icon={faHorseHead}
                      width="18"
                      className="treasure"
                    />{' '}
                    - has a Treasure available that amplifies their kit,
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faClock}
                      width="18"
                      className="limited"
                    />{' '}
                    - is a limited character that isn't available in the general
                    pool,
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faDollarSign}
                      width="18"
                      className="cost"
                    />{' '}
                    - heavy investment is required to play the character at
                    their full potential.
                  </li>
                </ul>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Meta Lines</Accordion.Header>
            <Accordion.Body>
              <p>
                The concept of usability in each game-mode can be different,
                even if a unit is good. Tier Lists tend to only show the
                strengths of certain characters but can fail to show how usable
                they might be in situations. An SS tier character in Campaign
                Tier List, although strong, needs to compete for a spot in 1
                team for campaign which only has 5 units. At the same time an SS
                tier character in PvP or Boss (Solo), who are again very strong
                units, compete for a spot in 3 to 5 teams! (15-25 spots). Hence,
                an SS tier Campaign unit will struggle to find a spot for
                Campaign, while an SS tier Boss(Solo)/PvP unit will see plenty
                of use in Raids / Special Arena! This is what we call "slot
                pressure".
              </p>
              <p>
                With the addition of Meta Lines, we intend to showcase the
                usability of a Nikke, and whether or not they really are worthy
                of investments or not depending on the user's play-style using:
              </p>
              <ul>
                <li>
                  <strong className="purple">Meta Line</strong>
                </li>
                <ul>
                  <li>
                    <strong>Units above the purple line</strong>: Meta, Highly
                    Usable Unit. Will not have a problem to fit into teams. The
                    absolute best units for this game mode. These units make the
                    game easy.
                  </li>
                </ul>
                <li>
                  <strong className="green">
                    Best replacement / Situational
                  </strong>
                </li>
                <ul>
                  <li>
                    <strong>Units above green line</strong>: Either they are the
                    best units to use if you don't have units above purple, or
                    their use is situational (i.e. depends on element or design
                    of stage/boss, requirement of heals/taunts, etc.). An
                    example is: Maid Privaty would be used over the likes of 2B
                    against a boss like Crystal Chamber in Solo Raids, while the
                    opposite would happen if the Boss was fire weak and far
                    away, like Stormbringer Solo Raid.
                  </li>
                  <li>
                    <strong>Units below green line</strong>: These units are
                    often used when players do not have units above green line.
                    If you have all units, these units will struggle to find a
                    spot in meta teams, and are often used for their niche.
                    (such as Ade's buff dispel, Nihilister being used for fire
                    elemental QTE barriers, Admi being used for her reload
                    speed, etc.)
                  </li>
                </ul>
              </ul>
              <p>
                <strong>Note</strong>: Meta Lines are more applicable for
                commanders who have most units already available. If you are a
                New Commander, using tiers as a literal guide will be more
                beneficial overall.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Ratings explained</Accordion.Header>
            <Accordion.Body>
              <h6>Story (LD and HD)</h6>
              <ul>
                <li>
                  <strong>SSS</strong> - Powerful, versatile units that can be
                  used to bruteforce most stages. Either they have a godly
                  damage output, or they have unrivaled supporting capabilities.
                  They have a comprehensive kit that barely has any weaknesses.
                </li>
                <li>
                  <strong>SS</strong> - Strong units featuring remarkable DPS
                  and supportive capability. They are, however, a bit niché, and
                  some are used situationally depending on the stage design or
                  element. They are deployed from time to time even outside
                  their best form because they are quite versatile as well but
                  overall weaker than SSS.
                </li>
                <li>
                  <strong>S</strong> - These units are much more situational
                  compared to their SS counterparts but can be used with success
                  if you know what you are doing (and have no access to better
                  alternatives). They function more as replacements. Their
                  overall performance is much weaker, and pushing with them will
                  not feel as rewarding/comfortable.
                </li>
                <li>
                  <strong>A</strong> - These units are rarely effective, and are
                  technically out of the actual meta for campaign. They cannot
                  compete with the top units, but occasionally might be able to
                  help progress in a stage or two. Tribe tower meta units mostly
                  exist here.
                </li>
                <li>
                  <strong>B</strong> - These units are very hard to use. They
                  can even be considered replacements for the best company tribe
                  tower units. In campaign however, there is at best one
                  situation in 30+ chapters where they may be able to fit in,
                  and that too only if they are fully built. Even then, it will
                  definitely be easier to use higher tier units and clear out
                  the stage.
                </li>
                <li>
                  <strong>C</strong> - Highly niche. It's improbable that these
                  units are ever used in high deficit, but they are at the very
                  least better than D tier units. Maybe in tribe tower these
                  units can rarely find a use.
                </li>
                <li>
                  <strong>D</strong> - These units are barely usable. They
                  provide little to nothing for their teams, have harsh
                  requirements for their skills, and have little effect even if
                  their skills are activated properly.
                </li>
                <li>
                  <strong>E</strong> - R unit hangout stage.
                </li>
              </ul>
              <h6>Bossing</h6>
              <ul>
                <li>
                  <strong>SSS</strong> - Powerful, versatile units that have a
                  godly damage output or have unrivaled supporting capabilities.
                  They have a comprehensive kit that works against almost every
                  boss, and they are often the primary choice if there is no
                  better substitute.
                </li>
                <li>
                  <strong>SS</strong> - Strong units that are often the second
                  choice. Impactful DPS and supportive skills. They are very
                  likely deployed in every season of Solo Raid. Some of them
                  also function as enablers and must be present for their most
                  optimal team to flourish. Without them, the overall usability
                  of the team would plummet.
                </li>
                <li>
                  <strong>S</strong> - Units that have decent DPS and acceptable
                  supportive capability but are secondary to their big sisters
                  in the SS rank. They either complement each other, require
                  them to excel, or help push them to their true limits. They
                  are unlikely to be deployed alone, and some of them may be
                  situational.
                </li>
                <li>
                  <strong>A</strong> - Units that are waiting for that one boss.
                  Highly specific units that are used when the right time and
                  situation comes. However, in other scenarios, these units will
                  falter.
                </li>
                <li>
                  <strong>B</strong> - Casual friendly usable units that can
                  either help with survivability, deal okayish damage, or can at
                  the very least buff their team to an extent. These units are
                  not meta but if the player has incomplete rosters, they can
                  definitely make use of them. In extremely harsh situations,
                  such as need fkr cleanse, these units may shine bright.
                </li>
                <li>
                  <strong>C</strong> - Extremely mid tier units. They are useful
                  can be for players with incomplete rosters who come up against
                  Elemental Shields and don't have enough units of the right
                  element.. However, their performance is never on par with the
                  top units. These units have glaring problems but are at the
                  very least usable in their own extreme niche.
                </li>
                <li>
                  <strong>D</strong> - These units are barely usable. They
                  provide little to nothing for their teams, have harsh
                  requirements just to activate their skills, and have little
                  effect even if their skills are activated properly.
                </li>
                <li>
                  <strong>E</strong> - R unit hangout room.
                </li>
              </ul>
              <h6>PVP</h6>
              <ul>
                <li>
                  <strong>SSS</strong> - Units used almost everywhere in PvP.
                  There is no good reason to exclude them, and if someone does,
                  that means they are challenging the norm and being creative.
                </li>
                <li>
                  <strong>SS</strong> - Units with high presence and overall
                  power. Some of them may be niché, but in that niché, they far
                  outclass their competitors. Otherwise, they can be seen almost
                  everywhere but are not necessarily required for proper
                  compositions to exist.
                </li>
                <li>
                  <strong>S</strong> - Awesome alternatives for people who want
                  to experiment with new teams, have no access to better units,
                  or want to throw off opponents with some novel builds. These
                  units usually have an innate drawback or are too expensive
                  investment wise, or their niché is very rare. Clip SGs who
                  serve as batteries also belong here.
                </li>
                <li>
                  <strong>A</strong> - Niche or second best replacements fits
                  them best. In PvP, not many units can be effectively used. The
                  remainder of units that can possibly be used to win are
                  present in this tier. They also might have niche or "special"
                  usage that is rarely effective.
                </li>
                <li>
                  <strong>B</strong> - Decent units for when one is just
                  starting out. Incomplete rosters can especially make use of
                  these units to boost their teams performance. For older
                  players, this tier is more or less irrelevant. These units may
                  be used to increase combat power for the team if going up
                  against a much stronger opponent, since at the very least they
                  can provide something to the team, which is better than
                  nothing.
                </li>
                <li>
                  <strong>C</strong> - Will never be used by veteran players.
                  May be used when starting out. These units provide barely
                  anything to the team but can help, even if the benefits are
                  small.
                </li>
                <li>
                  <strong>D</strong> - Bad SSR and SR units exist here. These
                  units are unusable and are very easily countered. If you see
                  these units in opponent teams, chances are that you can make
                  something that can beat them easily. Of course, as long as you
                  are not in critical CP deficit.
                </li>
                <li>
                  <strong>E</strong> - R unit hangout zone.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              Changelog <span className="tag updated">Updated!</span>
            </Accordion.Header>
            <Accordion.Body>
              <h6>13.10.2024</h6>
              <ul>
                <li>
                  <strong>[Phantom]</strong> has been added to the tier list.
                </li>
              </ul>
              <h6>07.10.2024</h6>
              <ul>
                <li>
                  We have removed the <strong>Boss (Adds)</strong> tier list.
                  After receiving a lot of feedback, the split between Boss
                  (Solo) and Boss (Adds) only brought a lot of confusion. So we
                  decided to remove the Adds bossing tier list and just keep a
                  single Bossing tier list moving forward. We are also working
                  on revamping the Story (LD) and PVP tier lists, so expect some
                  big changes to happen there soon!
                </li>
              </ul>
              <h6>06.10.2024</h6>
              <ul>
                <li>
                  <strong>[Quency: Escape Queen]</strong> has been added to the
                  tier list.
                </li>
              </ul>
              <h6>21.09.2024</h6>
              <ul>
                <li>
                  <strong>[Rouge]</strong> has been added to the tier list.
                </li>
              </ul>
              <h6>13.09.2024</h6>
              <ul>
                <li>
                  <strong>[Misato Katsuragi]</strong> has been added to the tier
                  list.
                </li>
              </ul>
              <h6>06.09.2024</h6>
              <ul>
                <li>
                  <strong>[Mari Makinamii]</strong> has been added to the tier
                  list.
                </li>
              </ul>
              <h6>30.08.2024</h6>
              <p>New additions:</p>
              <ul>
                <li>
                  <strong>[Rei Ayanami]</strong> has been added to the tier
                  list.
                </li>
              </ul>
              <p>
                We have revamped the Story, Bossing and PVP tier list and here's
                the list of the most important changes. Thank you all for the
                feedback we have received recently!
              </p>
              <ul>
                <li>
                  <strong>Story:</strong>
                </li>
                <ul>
                  <li>
                    <strong>Asuka</strong> (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    SSS): Core Damage boost proves to be more valuable than
                    expected for Alice, Modernia, and Rei. She also has similar
                    performance to Alice in Campaign, where optimum conditions
                    are unachievable.
                  </li>
                  <li>
                    <strong>B. Soda</strong> (A{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    S): The only Iron wiper for Electric stages. Favorable
                    against stages that spawn a lot of fragile mobs weak to
                    nukes like hers. Full Burst extension can either help
                    solidify or break your team (assuming no CDR). She also
                    deals fixed skill damage upon hitting enemies, regardless of
                    how many pellets land.
                  </li>
                  <li>
                    <strong>SAnis</strong> (S{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    SS): Simply the enabler of Electric teams. Without her (and
                    also Crown), Electric teams just don't feel like worth
                    using. Reason? They want maximum reload speed. She also has
                    soft AoE damage that can help you manage distant mobs that
                    other units cannot reach (because S2 has no range).
                  </li>
                  <li>
                    <strong>Ein</strong> (S{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    SS): True damage is goated with stat penalty. Also, constant
                    ATK buff stabilizes her damage, and her wipe can easily
                    finish off enemies.
                  </li>
                  <li>
                    <strong>Scarlet</strong> (SSS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    SS): She doesn't really become weak, but more like we are
                    moving her so that she harmonizes with the tier list rework.
                    Nowadays, Scarlet alone does not feel as rewarding as she is
                    before (likely due to CP inflation). Fortunately, Crown +
                    SAnis fixes her janky basic attacks. She is also rivaled by
                    Ein and exhibits similar performance. Both have wipes and
                    are still useful.
                  </li>
                  <li>
                    <strong>Tia</strong> (SSS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    SS): Naga has decided to leave her friend behind and "mated"
                    with Crown. Tia, who is now lonely, has somewhat become
                    obsolete. She is still the best B1 available if you do not
                    have Crown.
                  </li>
                  <li>
                    <strong>Dorothy</strong> (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): Requires Privaty to work well, which leads to slot
                    pressure in Campaign. Alone, she does not do much. In fact,
                    with higher stat penalty, her ATK may even be too low to
                    even make an impression, let alone if you don't have ATK OL.
                  </li>
                  <li>
                    <strong>Bunny Alice</strong> (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): A reliable burst healer that does not sacrifice another
                    Burst Skill. We believe she fulfills that purpose well, but
                    in Campaign, Rapunzel just integrates better than her. The
                    reason is that Rapunzel allows you to burst one more time in
                    RH B1 comps (and you can even get another RH B1 burst in the
                    following rotation, bringing the total to 4).
                  </li>
                  <li>
                    <strong>Volume, Dolla, SHelm</strong> (S{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    A): Generally useless units that are used for CDR. They
                    don't do much and are just fillers/alternatives once you get
                    better ones. Nowadays, we have more options to choose from,
                    and oftentimes you can win without CDR.
                  </li>
                </ul>
                <li>
                  <strong>Bossing (Solo):</strong>
                </li>
                <ul>
                  <li>
                    <strong>Asuka</strong> (SSS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    SS): The demotion is not because she performs below
                    expectation, but rather, we are fixing the tier list so that
                    it is easier to differentiate meta units across tiers and
                    within the same rank. In the past, our gap of tolerance
                    before a unit is promoted or demoted was too large. About
                    10-20% of performance difference was not enough reason for
                    us to place them in a different tier. However, now, with
                    this update that tightens the scope from S~SSS and demotes
                    many units from S to A, we can now visualize/highlight these
                    performance gaps much more conveniently.
                  </li>
                  <ul>
                    <li>
                      Asuka has a powerful DPS potential, and her supportive
                      buffs are outstanding (better than anything). The reason
                      for her demotion is the fact that it is hard to scale her
                      further, and her support skills simply deactivate when
                      core is not present. Both Alice and Asuka lose a
                      tremendous amount of damage if an enemy does not have
                      core. But, Alice is different in the way that she still
                      can buff SBS (who excel against bosses with no core and
                      have relatively higher DPS among the meta units).
                    </li>
                    <li>
                      Meanwhile, Asuka loses all her support capabilities under
                      these circumstances. She's still a great DPS, though, and
                      comparatively stronger than a lot of other meta Nikkes
                      whose damage is inflated due to core presence (i.e.
                      Modernia). She also deals bonus damage against Wind.
                    </li>
                    <li>
                      In other words, Asuka would have deserved SSS if her
                      support skill is still usable against no-core enemies, but
                      that is not the case, making her supportive side less
                      versatile than Alice's.
                    </li>
                    <li>
                      Asuka still remains SSS tier in Bossing (Adds) due to her
                      Pierce.
                    </li>
                  </ul>
                  <li>
                    <strong>Modernia</strong> (SSS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    SS): The reason for her "demotion" is that her DPS doesn't
                    live up to the meta of the meta anymore (Alice, SBS). She
                    does not really offer anything except DMG. Her burst gen is
                    very high, however, and she can help teams deal with
                    projectiles and mobs, but there is no reason for her to be
                    SSS anymore. Her 15s Full Burst also makes her harder to
                    integrate in non 1-1-3 teams. She also cannot benefit from
                    Crown's ATK buff (since does not want to Burst).
                  </li>
                  <li>
                    <strong>xLudmilla</strong> (SSS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    SS): Still one of the most versatile semi-DPS and support
                    B3, but while Asuka's supportive capability plummets when
                    there is no core, Winter Ludmilla's DPS is the one affected
                    instead when there is no core. Her supportive capability
                    remains intact.
                  </li>
                  <li>
                    <strong>Ein</strong> (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): Ein offers excellent DPS, and her True Damage proves
                    useful against bosses with high DEF. Ein is also more
                    scalable than Scarlet as she can benefit from True Damage
                    buffs. The reason why she is in S is because Ein does not
                    feel that worth using alone. Her DPS is high but not
                    towering, and she somewhat relies on SAnis to perform or at
                    least compete with the DPS from more superior teams.
                  </li>
                  <li>
                    <strong>Laplace (Treasure)</strong> (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): Laplace Treasure has astronomical multipliers. Her
                    problem is the lack of ATK buff, and building an optimal
                    team around her at the moment is challenging. For example,
                    FrimaTr can be used to buff LaplaceTr and Ein, but then who
                    is the B2? If you use Rem, then you get slot pressure from
                    using Dolla/SHelm. And if you use Rem, Ein will not get ATK
                    buff. Similar problems arise when you try to perfect this
                    team because there will always be one DPS who gets
                    sacrificed. However, we will see if the introduction of Mari
                    will change her rating (again).
                  </li>
                  <li>
                    <strong>Rem</strong> (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): Powercrept by Crown. Still a decent healer and semi-DPS.
                    Can buff Rocket Launchers. It is just hard to slot her into
                    various teams nowadays since there is always a better
                    option. Rem may still be used against Fire bosses.
                  </li>
                  <li>
                    <strong>S.Sakura</strong> (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): Summer Sakura has big multipliers, but her issue is that
                    the team needs to destroy parts regularly to maximize her
                    damage. Unfortunately, not many bosses present this
                    behavior. SSakura also doesn't have ATK buffs in her kit and
                    has to rely on supports to shine. Thankfully, due to the
                    rising trend of Elemental, she may still see use against
                    Iron bosses, despite these setbacks.
                  </li>
                  <li>
                    <strong>Bunny Alice</strong> (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): We kind of expected too much from B. Alice being the
                    "core unit of SG team because she fixes Tove's issues", but
                    at the end most people prefer to reroll Tove's RNG than to
                    use B. Alice and lose damage due to slot pressure. In fact,
                    people now use SG teams with Blanc and Noir, so no more lack
                    of healers! B. Alice still offers nice ammo support, though,
                    and her healing does not sacrifice the use of other B1's
                    Burst Skill, so she still rocks
                  </li>
                  <li>
                    <strong>Maid Privaty</strong> (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): Similar reasoning as the one we gave for Ein. Maid:
                    Privaty is a good DPS indeed, but the problem is that she
                    needs babysitting. Yes, her entire kit just doesn't work
                    without Tove or Leona. And when she does, she still relies
                    on BSoda to enable her. Moreover, she is only preferred
                    against Water element since usually there are better options
                    against other elements. For instance, Sugar against Electric
                    bosses.
                  </li>
                  <li>
                    <strong>2B, Diesel (Treasure)</strong> (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): Simply power crept. With the incoming pierce support of
                    Mari, we think that Diesel will now have an extremely hard
                    time finding a team to fit into. Alongside that, with the
                    influx of not 1, but 2 Meta fire B3s in Asuka and Rei, 2B
                    will now have a hard time fitting into top 5 teams even in
                    fire raids, but still might pop in for rare situations due
                    to her ability to tank insane amounts of damage which often
                    are directed to the highest ATK unit.
                  </li>
                  <li>
                    <strong>S.Rosanna</strong> (S{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    SS): A nikke who has shown how drastic dilution can be.
                    Summer Rosanna shines bright as she provides a very unique
                    Damage Taken debuff, and allows 3 DPS to benefit from it!
                    Each and every current meta DPS is filled to the brim with
                    ATK and Attack Damage buffs, as well as some even buffing
                    damage dealt to core. All of these self buffs dilute the
                    help they can receive from most meta B2s, such as Tia+Naga
                    and even Crown. This can especially be seen in the recent
                    releases of Asuka and Rei. Rosanna provides an almost always
                    undiluted Damage Taken Debuff that helps all units alike.
                    This helps to reduce pressure from Crown and Tiga as they
                    can buff some other units better comparatively than they
                    could buff Asuka for example. This aid in lowering slot
                    pressure is highly appreciated.
                  </li>
                </ul>
                <li>
                  <strong>PVP:</strong>
                </li>
                <ul>
                  <li>
                    RedHood B1 (S{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    SS): The best offensive B1 support unit. Without her, some
                    wipers don't work properly or don't have enough damage to
                    truly wipe the enemy.
                  </li>
                  <li>
                    B. Soda (S{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    SS): Comes with an aggressive and independent wipe. Counters
                    Noise's, Biscuit's, and SAnis's element and can evaporate
                    them easily once invested. Also can be used to deter or
                    punish unprotected Noise tanking.
                  </li>
                  <li>
                    Quiry (A{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    S): Stall teams need her to sustain considerably longer;
                    also doesn't feed Scarlet & Jackal. There is no stall
                    without Quiry.
                  </li>
                  <li>
                    Ein (S{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    SS): A damage over time unit with her true damage dealing
                    feathers earns her a spot above others. Her DPS from her
                    burst skill is quite strong and Ein has a serious shout for
                    being used in the champions arena as a main B3.
                  </li>
                  <li>
                    2B (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): Glaring counters. Can be countered in multiple ways.
                    Still exists as a good alternative overall.
                  </li>
                  <li>
                    S. Sakura (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): Needs ATK buff to actually deal big damage. Her kit is
                    self-limiting. Low burst gen and feeding is the main reason
                    she's being demoted. Still highly regarded as a B3, and even
                    as an off burst unit.
                  </li>
                  <li>
                    Kilo (SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S): Actually, her wipe is decently strong, but she is MG,
                    and she feeds. Nom nom.
                  </li>
                </ul>
              </ul>
              <h6>22.08.2024</h6>
              <ul>
                <li>
                  <strong>[Asuka Shikinami Langley]</strong> has been added to
                  the tier list.
                </li>
              </ul>
              <h6>19.08.2024</h6>
              <ul>
                <li>
                  <strong>[Frima - Treasure]</strong> and{' '}
                  <strong>[Viper - Treasure]</strong> have been added to the
                  tier list.
                </li>
              </ul>
              <h6>10.08.2024</h6>
              <ul>
                <li>
                  <strong>[Zwei]</strong> has been added to the tier list.
                </li>
              </ul>
              <h6>03.08.2024</h6>
              <ul>
                <li>
                  <strong>[Exia - Treasure]</strong> has been added to the tier
                  list.
                </li>
              </ul>
              <h6>26.07.2024</h6>
              <ul>
                <li>
                  <strong>[Ein]</strong> has been added to the tier list.
                </li>
              </ul>
              <h6>25.07.2024</h6>
              <ul>
                <li>
                  <strong>[Noah]</strong> SS &gt; SSS in PvP. Her burst gen has
                  been patched, she now hits with both bullets, which means her
                  burst gen is now ×2. This puts her burst gen above all Clip
                  SGs, and immensely increases her already near constant
                  presence in PvP.
                </li>
              </ul>
              <h6>15.07.2024</h6>
              <ul>
                <li>
                  <strong>[Diesel - Treasure]</strong> has been added to the
                  tier list.
                </li>
              </ul>
              <h6>12.07.2024</h6>
              <ul>
                <li>
                  <strong>[Rosanna: Chic Ocean]</strong> has been added to the
                  tier list.
                </li>
              </ul>
              <h6>06.07.2024</h6>
              <ul>
                <li>
                  <strong>[Sakura Summer]</strong> has been added to the tier
                  list.
                </li>
              </ul>
              <h6>22.06.2024</h6>
              <ul>
                <li>
                  <strong>[Laplace - Treasure]</strong> has been added to the
                  tier list.
                </li>
              </ul>
              <h6>20.06.2024</h6>
              <ul>
                <li>
                  <strong>[Clay]</strong> has been added to the tier list.
                </li>
              </ul>
              <h6>08.06.2024</h6>
              <ul>
                <li>
                  <strong>[Alice: Wonderland Bunny]</strong> has been added to
                  the tier list.
                </li>
              </ul>
              <h6>01.06.2024</h6>
              <ul>
                <li>
                  <strong>[Soda: Twinkling Bunny]</strong> has been added to the
                  tier list,
                </li>
                <li>
                  <strong>[D: Killer Wife]</strong> SS{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  SSS in all categories but PVP. While Liter still remains at
                  the top of the food chain, D: Killer Wife comes extremely
                  close. Not only is D simply a better support to the two best
                  DPS (Alice and Red Hood), she is also a better CDR unit for
                  campaign, since she has no ramp up time. While the ramp up
                  time with liter is low, at high deficit gameplay, it can be
                  the reason of your team surviving or dying. With D, we finally
                  have a support who can consistently replace Liter in most
                  situations. Hence, we are raising her rating back to where she
                  was placed when she was released.
                </li>
                <li>
                  <strong>[Privaty: Unkind Maid]</strong> S{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  SS in Boss (Solo). With Bunny Soda's release and Bunny Alice
                  incoming, we finally have the SG comp that Shift Up has been
                  trying to make since back in November, when Tove got released.
                  Not only does Tove have enough units to be considered a good
                  support now, the damage of the SG team is comparable to the
                  top teams since Soda not only does amazing damage herself, she
                  also buffs the likes of MaidPriv to her level! The SG team
                  also has completely different units that do not clash with the
                  existing meta! This is a huge point, since this means they
                  compete in damage while not sharing resources that may weaken
                  your other teams! While they may not see use in every raid, we
                  definitely believe that they will see use against a lot of
                  bosses! Another point to note is that both M.Priv and B.Soda
                  have AoE damage, allowing them perform better against bosses
                  with Adds!
                </li>
                <li>
                  <strong>[Tove]</strong> S{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  SS in Boss (Solo) and B{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  S in Boss (Adds).
                </li>
                <li>
                  <strong>[Leona]</strong> A{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  S in Boss (Solo) and C{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  A in Boss (Adds).
                </li>
                <li>
                  <strong>[Rapunzel]</strong> A{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  S in Boss (Solo). With Crown's arrival, Rapunzel has taken it
                  onto herself to be her pair, but only in situations where
                  there is dire need of healing. While she might not be the top
                  pick every time, she definitely can be a strong pick for most
                  occasions.
                </li>
                <li>
                  <strong>[Alice]</strong> S{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  SS in Campaign (Low Deficit). The biggest issue that Alice has
                  in Low Deficit and casual gameplay apart from the manual
                  intensiveness and high Skill requirement, is the extreme lack
                  of ammo. This was one of the biggest reasons as to why Alice
                  was favoured less for casual players. Now however, the ammo
                  problem can be circumvented with the introduction of crown,
                  who can buff reload speed, allowing Alice to hit more than
                  enough shots during burst. This, paired with Privaty being a
                  free unit, as well as Resilience cube being one of the early
                  game cubes, helps raise her standing even in casual gameplay!
                </li>
                <li>
                  <strong>[Novel]</strong> S{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  A in Boss (Solo). Once at the top of the ladder, now a remnant
                  of the past. Powercreeps upon powercreeps, Novel is slowly
                  dying out of the meta. That's to say, if she hasn't already.
                  She has not seen use at all at the very top branch of raids
                  for quite some time, be it UR or SR.
                </li>
                <li>
                  <strong>[Emilia]</strong> S{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  A in Boss (Solo) and SS{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  S in Boss (Adds). Emilia, while stronger than A2, fills the
                  same purpose as A2. Both are used when Bosses with parts are
                  in play since both of them scale immensely with the amount of
                  targets to hit. However, in most cases, both see a decline in
                  play, especially with newer units simply doing more DPS in
                  bosses with less number of parts or no parts.
                </li>
                <li>
                  <strong>[Kilo]</strong> A{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  B in Campaign (High Deficit) and S{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  A in Campaign (Low Deficit). While Kilo has a really good AoE,
                  one that scales with HP buffs as well, allowing for a max HP
                  buffer to heal the team along with buffing Kilo AoE, her
                  shield is immensely problematic. It can be destroyed very
                  easily in all formats, be it bossing or be it campaign, and
                  hence she struggles to perform to a higher level.
                </li>
              </ul>
              <h6>18.05.2024</h6>
              <p>
                Added <strong>[Trony]</strong> to the tier list.
              </p>
              <h6>29.04.2024</h6>
              <p>
                Made several changes to the tier list to better showcase the
                impact of Crown on the meta:
              </p>
              <ul>
                <li>
                  <strong>[Blanc]</strong> and <strong>[Noir]</strong> SSS{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  SS in Boss (Solo). With the release of Crown and Tia Naga,
                  this combo is now starting to show its age, and it's quite
                  noticeable the farther you push in Campaign. They are
                  considerably outclassed by newer releases. While they are
                  still meta, we are sorry to inform you that they have simply
                  been powercrept by Crown and the Schoolgirls.
                </li>
                <li>
                  <strong>[Rapunzel]</strong> S{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  SS in Campaign (High Deficit). Rapunzel has always held some
                  sort of value throughout NIKKE's history. Due to her immensely
                  good passive heal abilities, she was featured in Campaign
                  multiple times. While we may have better options for the flex
                  spot, Rapunzel is also one of the best supports for Crown. To
                  maximize Crown's S2 uptime, one needs to continually heal her.
                  Not to mention, Rapunzel also has that clutch revive. Her
                  heal, revive and indirect buff to overall damage of the team
                  due to increasing Crown's Skill 2 has helped her case as one
                  of the better Nikkes for Crown teams's flex spot.
                </li>
                <li>
                  <strong>[Rosanna]</strong> S{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  SS in PvP. Rosanna has proven to be a truly amazing unit for
                  PvP and is a must-have/must-invest for PvP enjoyers. Even if
                  she's MG, and with the addition of new units she seems to have
                  been consistently usable, be it for Defense teams or Attack
                  teams. While she can struggle in DEF teams if the attacker
                  knows what they're doing, and needs high investments to work,
                  her unique nuke + hyper Burst Gen gain makes her one of the
                  best in business for Attack comps!
                </li>
                <li>
                  <strong>[Scarlet: Black Shadow]</strong> SS{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  SSS in Boss (Adds) and S{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  SS in Story (High Deficit). The introduction of Crown shook
                  the meta heavily for Campaign, as units supersede one another
                  in a seemingly unending cycle. This includes Scarlet: Black
                  Shadow, who has risen to eminence thanks to Crown. While she
                  may not be the best for all occasions, her performance is
                  becoming more and more in line with our overreaching
                  expectations. In the past, SBS's strength was held back by the
                  lack of proper support. Naga buffs Core Damage, which can be
                  translated as a ~45% buff to final damage (can be higher or
                  lower depending on Crits, Range Bonus, etc.), but SBS's
                  primary damage is her distributed damage of her Skill 1, which
                  does not benefit from this core damage buff. Hence, SBS was
                  receiving upto 45% less buffs than her peers who were able to
                  benefit from her kit to the fullest and were able to perform
                  at a higher level. This is different with Crown, who grants
                  "excessive" Attack Damage as well as ATK to units who use
                  their Burst Skill, all of which benefit SBS! And the best
                  part? This is only ONE unit, which means you can run 1-1-3
                  comps where we can fit in Alice+other buffers, or even another
                  DPS who can help us clear a path by killing small raptures so
                  that SBS can nuke the Boss! This is now applicable in both
                  Campaign (High Deficit) and Boss (with Additional Raptures).
                  While Scarlet:Black Shadow still can't compete with the likes
                  of Alice, Modernia, and Red Hood, she does create a power gap
                  between SS and S tier now for campaign! Crown is a gift that
                  keeps on giving! Thanks to this, we are raising her rank
                </li>
              </ul>
              <h6>27.04.2024</h6>
              <ul>
                <li>Added [Kilo] to the tier list.</li>
              </ul>
              <h6>26.04.2024</h6>
              <ul>
                <li>Added [Crown] to the tier list.</li>
              </ul>
              <h6>14.04.2024</h6>
              <ul>
                <li>Added [Bay] to the tier list,</li>
                <li>Made several changes to the PVP tier list:</li>
                <ul>
                  <li>
                    <strong>[Emilia]</strong> SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    SSS - She is too strong and too valuable. Meta.
                  </li>
                  <li>
                    <strong>[Maid Privaty]</strong> S{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    SS - She has proven rival of Maiden and often is a better
                    choice for nuking opponents.
                  </li>
                  <li>
                    <strong>[Makima]</strong> SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S - With the arrival of new units, her use has declined due
                    to new ways of countering SGs have come to light.
                  </li>
                  <li>
                    <strong>[Drake]</strong> SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S - Nothing has changed. She's still very useful and can be
                    used in both attack and defence as the buffer. However she
                    cannot be used as main B3s with high effect so we demoted
                    her.
                  </li>
                  <li>
                    <strong>[Noir]</strong> SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S - Same as Drake.
                  </li>
                  <li>
                    <strong>[Pepper]</strong> SS{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    S - Nothing has changed. To fit in better with the tier list
                    rework, Pepper is now S tier. Similar to Rosanna, she can
                    nuke the highest attack unit. Her burst gen isn't
                    conditional like Rosanna's and overall she is a good
                    addition to the team. However, she is less flexible than the
                    units ranked above her.
                  </li>
                  <li>
                    <strong>[Harran]</strong> S{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    A - While Harran is still one of the best units to use early
                    game, but later on as commanders reach higher levels, the
                    disparity between HP and ATK stat is too much. Since at
                    higher levels HP has better scaling than ATK, Harran's nuke
                    becomes incapable of one-shotting opponents!
                  </li>
                  <li>
                    <strong>[Privaty]</strong> S{' '}
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      width="18"
                      className="arrow-red"
                    />{' '}
                    A - Privaty is also a unit who finds extreme usage early
                    game, but can fall prey to meta units once players start
                    having more complete rosters.
                  </li>
                </ul>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
        <div className="tier-list-header">
          <p className="title">
            Tier List{' '}
            <span>
              ({sortOption === 'Story_LD' && 'Story - low deficit'}
              {sortOption === 'Story_HD' && 'Story - high deficit'}
              {sortOption === 'Boss_Solo' && 'Bossing'}
              {sortOption === 'PVP' && 'PVP'})
            </span>
          </p>
          <p className="sub-title">
            You're currently viewing the{' '}
            <strong>
              {sortOption === 'Story_LD' && 'Story (low deficit)'}
              {sortOption === 'Story_HD' && 'Story (high deficit)'}
              {sortOption === 'Boss_Solo' && 'Bossing'}
              {sortOption === 'PVP' && 'PVP'}
            </strong>{' '}
            tier list.{' '}
            {sortOption === 'Story_LD' &&
              'This tier list is aimed toward casual players for Campaign, who prefer to advance with reduced difficulty, as well as for the mode Simulation Overclock.'}
            {sortOption === 'Story_HD' &&
              'This tier list refers to any late-game story-clearing content where people attempt to clear stages, whether in Campaign or Tower, with over >25% CP deficit.'}
            {sortOption === 'PVP' &&
              'It shows how a character performs in the Arena - both the normal arena and the three team variant (Special Arena).'}
            {sortOption === 'Boss_Solo' &&
              'It shows how a character performs in various fights against bosses - both those who are solo and those who spawn adds.'}{' '}
            Use the switcher below to view a different tier list.
          </p>
        </div>
        <div className="tier-list-switcher">
          <div
            className={`option early ${
              sortOption === 'Story_LD' && 'selected'
            }`}
            onClick={() => setSortOption('Story_LD')}
          >
            <p>Story (low deficit)</p>
          </div>
          <div
            className={`option late ${sortOption === 'Story_HD' && 'selected'}`}
            onClick={() => setSortOption('Story_HD')}
          >
            <p>Story (high deficit)</p>
          </div>
          <div
            className={`option boss ${
              sortOption === 'Boss_Solo' && 'selected'
            }`}
            onClick={() => setSortOption('Boss_Solo')}
          >
            <p>Bossing</p>
          </div>
          <div
            className={`option pvp ${sortOption === 'PVP' && 'selected'}`}
            onClick={() => setSortOption('PVP')}
          >
            <p>PVP</p>
          </div>
        </div>
        <div className="employees-filter-bar nikke">
          <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
        </div>
        <div className="custom-tier-list-nikke">
          <div className="custom-tier-header">
            <div className="tier-rating">
              <span>&nbsp;</span>
            </div>
            <div className="custom-tier-container">
              <div className="custom-header burst-1">
                Burst <span>I</span>
              </div>
              <div className="custom-header burst-2">
                Burst <span>II</span>
              </div>
              <div className="custom-header burst-3">
                Burst <span>III</span>
              </div>
            </div>
          </div>
          <div className="custom-tier tier-sss first">
            <div className="tier-rating sss">
              <span>SSS</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile burst-1">
                Burst <span>I</span>
              </div>
              <div className="custom-tier-burst burst-1">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'SSS')
                  .filter((emp) => emp.Burst_Type === 'I')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-2">
                Burst <span>II</span>
              </div>
              <div className="custom-tier-burst burst-2">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'SSS')
                  .filter((emp) => emp.Burst_Type === 'II')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-3">
                Burst <span>III</span>
              </div>
              <div className="custom-tier-burst burst-3">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'SSS')
                  .filter((emp) => emp.Burst_Type === 'III')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-ss">
            <div className="tier-rating ss">
              <span>SS</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile burst-1">
                Burst <span>I</span>
              </div>
              <div className="custom-tier-burst burst-1">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'SS')
                  .filter((emp) => emp.Burst_Type === 'I')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-2">
                Burst <span>II</span>
              </div>
              <div className="custom-tier-burst burst-2">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'SS')
                  .filter((emp) => emp.Burst_Type === 'II')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-3">
                Burst <span>III</span>
              </div>
              <div className="custom-tier-burst burst-3">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'SS')
                  .filter((emp) => emp.Burst_Type === 'III')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div
            className={`meta-line ${sortOption != 'Boss_Solo' && 'visible'}`}
          >
            <h5 className="meta-header meta">
              <FontAwesomeIcon icon={faAnglesUp} width="18" /> Meta{' '}
              <FontAwesomeIcon icon={faAnglesUp} width="18" />
            </h5>
          </div>
          <div className="custom-tier tier-s">
            <div className="tier-rating s">
              <span>S</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile burst-1">
                Burst <span>I</span>
              </div>
              <div className="custom-tier-burst burst-1">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'S')
                  .filter((emp) => emp.Burst_Type === 'I')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-2">
                Burst <span>II</span>
              </div>
              <div className="custom-tier-burst burst-2">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'S')
                  .filter((emp) => emp.Burst_Type === 'II')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-3">
                Burst <span>III</span>
              </div>
              <div className="custom-tier-burst burst-3">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'S')
                  .filter((emp) => emp.Burst_Type === 'III')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div
            className={`meta-line ${sortOption === 'Boss_Solo' && 'visible'}`}
          >
            <h5 className="meta-header meta">
              <FontAwesomeIcon icon={faAnglesUp} width="18" /> Meta{' '}
              <FontAwesomeIcon icon={faAnglesUp} width="18" />
            </h5>
          </div>
          <div
            className={`meta-line ${
              (sortOption === 'Boss_Adds' ||
                sortOption === 'Story_LD' ||
                sortOption === 'Story_HD') &&
              'visible'
            }`}
          >
            <h5 className="meta-header alt">
              <FontAwesomeIcon icon={faAnglesUp} width="18" /> Best replacement
              / situational <FontAwesomeIcon icon={faAnglesUp} width="18" />
            </h5>
          </div>
          <div className="fuse-ad-placeholder" data-fuse="22844297007"></div>
          <div className="custom-tier tier-a">
            <div className="tier-rating a">
              <span>A</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile burst-1">
                Burst <span>I</span>
              </div>
              <div className="custom-tier-burst burst-1">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'A')
                  .filter((emp) => emp.Burst_Type === 'I')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-2">
                Burst <span>II</span>
              </div>
              <div className="custom-tier-burst burst-2">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'A')
                  .filter((emp) => emp.Burst_Type === 'II')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-3">
                Burst <span>III</span>
              </div>
              <div className="custom-tier-burst burst-3">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'A')
                  .filter((emp) => emp.Burst_Type === 'III')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div
            className={`meta-line ${
              (sortOption === 'Boss_Solo' || sortOption === 'PVP') && 'visible'
            }`}
          >
            <h5 className="meta-header alt">
              <FontAwesomeIcon icon={faAnglesUp} width="18" /> Best replacement
              / situational <FontAwesomeIcon icon={faAnglesUp} width="18" />
            </h5>
          </div>
          <div className="custom-tier tier-b">
            <div className="tier-rating b">
              <span>B</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile burst-1">
                Burst <span>I</span>
              </div>
              <div className="custom-tier-burst burst-1">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'B')
                  .filter((emp) => emp.Burst_Type === 'I')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-2">
                Burst <span>II</span>
              </div>
              <div className="custom-tier-burst burst-2">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'B')
                  .filter((emp) => emp.Burst_Type === 'II')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-3">
                Burst <span>III</span>
              </div>
              <div className="custom-tier-burst burst-3">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'B')
                  .filter((emp) => emp.Burst_Type === 'III')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
          <div className="custom-tier tier-c">
            <div className="tier-rating c">
              <span>C</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile burst-1">
                Burst <span>I</span>
              </div>
              <div className="custom-tier-burst burst-1">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'C')
                  .filter((emp) => emp.Burst_Type === 'I')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-2">
                Burst <span>II</span>
              </div>
              <div className="custom-tier-burst burst-2">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'C')
                  .filter((emp) => emp.Burst_Type === 'II')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-3">
                Burst <span>III</span>
              </div>
              <div className="custom-tier-burst burst-3">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'C')
                  .filter((emp) => emp.Burst_Type === 'III')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-d">
            <div className="tier-rating d">
              <span>D</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile burst-1">
                Burst <span>I</span>
              </div>
              <div className="custom-tier-burst burst-1">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'D')
                  .filter((emp) => emp.Burst_Type === 'I')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-2">
                Burst <span>II</span>
              </div>
              <div className="custom-tier-burst burst-2">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'D')
                  .filter((emp) => emp.Burst_Type === 'II')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-3">
                Burst <span>III</span>
              </div>
              <div className="custom-tier-burst burst-3">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'D')
                  .filter((emp) => emp.Burst_Type === 'III')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-e">
            <div className="tier-rating e">
              <span>E</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile burst-1">
                Burst <span>I</span>
              </div>
              <div className="custom-tier-burst burst-1">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'E')
                  .filter((emp) => emp.Burst_Type === 'I')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-2">
                Burst <span>II</span>
              </div>
              <div className="custom-tier-burst burst-2">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'E')
                  .filter((emp) => emp.Burst_Type === 'II')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile burst-3">
                Burst <span>III</span>
              </div>
              <div className="custom-tier-burst burst-3">
                {visibleCharacters
                  .filter((emp) => emp[sortOption] === 'E')
                  .filter((emp) => emp.Burst_Type === 'III')
                  .sort((a, b) => (a.Slug > b.Slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <NikkeCharacter
                            tierID={emp.ID}
                            slug={emp.Slug}
                            mode="icon-tier"
                            enablePopover
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeTierListPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier list | NIKKE | Prydwen Institute"
    description="Tier list for Goddess of the Victory: NIKKE that rates all characters."
    game="nikke"
  />
);
